.club-students {
    .pending-row {
        background-color: #fff7e6;
        
        &:hover > td {
            background-color: #fff1cc !important;
        }
    }
    
    .ant-table-tbody > tr.pending-row > td {
        background-color: #fff7e6;
        
        &:hover {
            background-color: #fff1cc !important;
        }
    }
} 