.footer {
    padding: 50px 200px;
    color: #2c3e50;
    background: linear-gradient(135deg, #f4f6f7 0%, #e8f4ea 100%);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    overflow: hidden;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(44, 62, 80, 0.05);
        z-index: 1;
    }

    @media (max-width: 1280px) {
        padding: 50px 100px;
    }

    @media (max-width: 979px) {
        padding: 50px 50px;
    }

    @media (max-width: 768px) {
        padding: 30px;
    }

    .footer__container {
        display: flex;
        justify-content: space-between;
        align-items: start;
        flex-wrap: wrap;
        color: #fff;
        position: relative;
        z-index: 2;

        @media (max-width: 768px) {
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        .footer-links {
            h3 {
                margin-top: 0;
                color: #2c3e50;
            }
        }

        .footer-logos {
            display: flex;
            justify-content: space-between;
            gap: 20px;
            flex-wrap: wrap;

            @media (max-width: 768px) {
                gap: 20px;
                flex-direction: column;
                text-align: center;
                justify-content: center;
                align-items: center;
            }

            .footer-logo {
                color: #2c3e50;
                text-decoration: none;
                transition: transform 0.3s ease;

                &:hover {
                    transform: scale(1.05);
                }

                .footer-logo__img {
                    background-color: rgba(255, 255, 255, 0.9) !important;
                    border-radius: 15px;
                    cursor: pointer;
                    width: 140px;
                    height: 60px;
                    padding: 10px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
                    transition: box-shadow 0.3s ease;

                    &:hover {
                        box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
                    }

                    @media (max-width: 768px) {
                        width: 120px;
                        height: 50px;
                    }
                }

                h4 {
                    font-size: 16px;
                    margin: 0 0 10px 0;
                    font-weight: 300;
                    opacity: 0.7;
                    color: #2c3e50;
                }

                img {
                    width: 100%;
                    object-fit: contain;
                }
            }

            .footer-logo-container {
                h4 {
                    font-size: 16px;
                    margin: 0 0 10px 0;
                    font-weight: 300;
                    opacity: 0.7;
                    color: #2c3e50;
                }

                .footer-logo__imgs {
                    display: flex;
                    align-items: center;
                    gap: 10px;

                    .footer-logo__img {
                        background-color: rgba(255, 255, 255, 0.9) !important;
                        border-radius: 15px;
                        cursor: pointer;
                        width: 140px;
                        height: 60px;
                        padding: 10px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
                        transition: box-shadow 0.3s ease, transform 0.3s ease;

                        &:hover {
                            box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
                            transform: scale(1.05);
                        }

                        @media (max-width: 768px) {
                            width: 120px;
                            height: 50px;
                        }

                        img {
                            width: 100%;
                            object-fit: contain;
                        }
                    }
                }
            }
        }

        @media (max-width: 768px) {
            width: 100%;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;

        }

        p {
            margin-top: 20px;
            font-size: 16px;
            line-height: 1.5;
        }

        .footer-social {
            margin-top: 0;

            @media (max-width: 768px) {
                margin-top: 20px;
            }

            h3 {
                margin-top: 0;
                font-weight: 300;
                opacity: 0.8;
                color: #2c3e50;
            }

            a {
                margin-right: 15px;
                font-size: 30px;
                color: #2c3e50;
                transition: all 0.3s ease-in-out;
                opacity: 0.6;

                &:hover {
                    color: #41b653;
                    opacity: 1;
                    transform: scale(1.2);
                }
            }
        }

        .footer-links {
            width: 20%;

            @media (max-width: 768px) {
                width: 100%;
                text-align: center;
                margin-top: 20px;
            }

            h3 {
                font-size: 20px;
                margin-bottom: 20px;
                font-weight: 300;
                opacity: 0.8;
                color: #2c3e50;
            }

            ul {
                list-style: none;
                padding: 0;
                margin: 0;

                li {
                    margin-bottom: 10px;

                    a {
                        color: #2c3e50;
                        font-size: 16px;
                        transition: all 0.3s ease-in-out;
                        text-decoration: none;
                        position: relative;
                        opacity: 0.8;

                        &::after {
                            content: '';
                            position: absolute;
                            width: 0;
                            height: 2px;
                            bottom: -3px;
                            left: 0;
                            background-color: #41b653;
                            transition: width 0.3s ease-in-out;
                        }

                        &:hover {
                            color: #41b653;
                            opacity: 1;

                            &::after {
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }

    .footer-terms {
        width: 100%;
        margin-top: 50px;
        font-size: 14px;
        line-height: 1.5;
        display: flex;
        border-top: 1px solid rgba(44, 62, 80, 0.1);
        color: #2c3e50;
        justify-content: space-between;
        padding-top: 20px;
        position: relative;
        z-index: 2;

        @media (max-width: 768px) {
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
        }

        a {
            color: #2c3e50;
            transition: all 0.3s ease-in-out;
            text-decoration: none;
            opacity: 0.8;

            &:hover {
                color: #41b653;
                opacity: 1;
                text-decoration: underline;
            }
        }
    }
}