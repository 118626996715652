.attendance {
    padding: 20px;

    @media (max-width: 768px) {
        padding: 0;
    }

    .header {
        display: flex;
        align-items: start;
        gap: 20px;
        margin: 0;

        h1 {
            margin: 0;
        }

        @media (max-width: 768px) {
            flex-direction: column;
            gap: 10px;
            align-items: start;

            h1 {
                margin: 0;
            }

            .ant-select {
                width: 100%;
                margin-bottom: 10px;
            }

            .ant-form-item {
                margin-bottom: 0;
            }
        }
    }

    .search {
        display: flex;
        align-items: center;
        gap: 20px;
        margin: 20px 0;

        @media (max-width: 768px) {
            display: block;

            .ant-select {
                margin-top: 10px;
            }
        }
    }

    .students {
        .student {
            background-color: #e4e4e45e;
            margin-bottom: 15px;
            padding: 0 20px 10px 20px;
            border-radius: 10px;

            .info {
                h2 {
                    margin: 0;
                }

                display: flex;
                align-items: center;
                gap: 20px;
            }

            .data {
                display: flex;
                align-items: center;
                gap: 20px;
                flex-wrap: wrap;

                .boxes {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    flex-wrap: wrap;

                    .box {
                        display: flex;
                        align-items: center;
                        gap: 5px;

                        p {
                            margin: 0;
                        }
                    }
                }
            }
        }
    }
}