.signin {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 80vh;
    background-color: #f5f5f5;

    .container {
        width: 500px;
        background-color: #fff;
        border-radius: 10px;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.08);
        transition: all 0.3s ease;

        .signin-header {
            margin-bottom: 20px;
            text-align: center;
            margin-top: 30px;

            h1 {
                font-size: 2.5rem;
                color: #333;
                margin: 0 0 20px 0;
            }

            p {
                font-size: 1.2rem;
                margin: 0;
                color: #333;
            }
        }

        .form {
            padding: 20px 60px;

            .form-group {
                margin-bottom: 20px;

                label {
                    font-size: 1.2rem;
                    color: #333;
                    margin-bottom: 10px;
                }

                input {
                    width: 100%;
                    padding: 10px;
                    border: 1px solid #333;
                    border-radius: 5px;
                    font-size: 1rem;
                }
            }

            .form-group:last-child {
                margin-bottom: 0;
            }

            .form-group button {
                width: 100%;
                padding: 10px;
                border: none;
                border-radius: 5px;
                font-size: 1rem;
                background-color: #333;
                color: #fff;
                cursor: pointer;
                transition: background-color 0.3s;

                &:hover {
                    background-color: #555;
                }
            }
            
            .forgot-password {
                text-align: center;
                margin-top: 5px;
                
                button {
                    color: #41b653;
                    font-size: 0.9rem;
                    padding: 0;
                    
                    &:hover {
                        color: #1bd637;
                        text-decoration: underline;
                    }
                }
            }

            .signup-link {
                text-align: center;
                margin-top: 20px;

                p {
                    font-size: 1rem;
                    color: #333;

                    a {
                        color: #41b653;
                        text-decoration: none;
                        transition: color 0.3s;

                        &:hover {
                            color: #1bd637;
                        }
                    }
                }
            }
        }
    }
}

// Modal styling
.reset-password-form {
    padding: 10px 0;
    
    p {
        margin-bottom: 15px;
        color: #555;
    }
    
    .ant-input {
        height: 40px;
        border-radius: 6px;
        
        &:focus {
            border-color: #41b653;
            box-shadow: 0 0 0 2px rgba(65, 182, 83, 0.2);
        }
    }
}

// Override Ant Design's modal styles for a more modern look
.ant-modal-content {
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.15);
}

.ant-modal-header {
    border-bottom: none;
    padding: 20px 24px;
}

.ant-modal-title {
    font-size: 1.3rem;
    color: #333;
}

.ant-modal-footer {
    border-top: none;
    padding: 10px 24px 20px;
}

// Media queries for responsive design
@media (max-width: 576px) {
    .signin .container {
        width: 90%;
        max-width: 450px;
    }
    
    .signin .container .form {
        padding: 20px 30px;
    }
}