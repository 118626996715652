.new_registartion {
    h1 {
        margin: 0;
    }

    .action_Buttons {
        display: flex;
        gap: 10px;
    }

    .table {
        @media (max-width: 768px) {
            width: 90vw;
            overflow-y: auto;
        }
    }
}