.code_clubs {
    h1 {
        margin: 0;
    }

    .club {
        background-color: #f5f5f5;
        padding: 10px 20px;
        margin-bottom: 10px;
        border-radius: 10px;
        cursor: pointer;
        transition: all 0.2s ease-in-out;

        &:hover {
            background-color: #e5e5e5;
        }

        h2,
        p {
            font-size: 1;
            margin: 0 0 4px 0;
        }
    }
}

.drawer_club_students {
    h2 {
        margin: 0 0 10px 0;
    }

    h3 {
        margin: 0 0 15px 0;
        font-weight: 500;
    }

    .student {
        background-color: #f5f5f5;
        padding: 10px 20px;
        margin-bottom: 10px;
        border-radius: 10px;
        cursor: pointer;
        transition: all 0.2s ease-in-out;

        &:hover {
            background-color: #e5e5e5;
        }

        h2,
        p {
            font-size: 1;
            margin: 0 0 4px 0;
        }
    }
}