.training {
    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 24px;
        
        .header-title {
            h2 {
                margin: 0;
                font-weight: 600;
                color: #111827;
            }
        }
    }
    
    .modules-grid {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
        gap: 24px;
        
        @media (max-width: 768px) {
            grid-template-columns: 1fr;
        }
        
        .module-card {
            height: 100%;
            border-radius: 8px;
            overflow: hidden;
            box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
            transition: all 0.3s ease;
            
            &:hover {
                box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
                transform: translateY(-4px);
            }
            
            .ant-card-head {
                padding: 12px 16px;
                background-color: #f9fafb;
                border-bottom: 1px solid #f0f0f0;
                
                .ant-card-head-title {
                    font-size: 18px;
                    font-weight: 600;
                    color: #111827;
                }
            }
            
            .card-actions {
                display: flex;
                gap: 4px;
            }
            
            .ant-card-body {
                padding: 16px;
            }
            
            .module-date {
                margin-bottom: 12px;
            }
            
            .module-description {
                margin-bottom: 16px;
                color: #4b5563;
                font-size: 14px;
            }
            
            .ant-tabs-nav {
                margin-bottom: 12px;
            }
            
            .tab-content {
                display: flex;
                flex-direction: column;
                gap: 16px;
                
                .video-container {
                    position: relative;
                    width: 100%;
                    padding-top: 56.25%; /* 16:9 Aspect Ratio */
                    
                    iframe {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        border-radius: 4px;
                    }
                }
                
                .material-link {
                    margin-top: 8px;
                    
                    a {
                        display: inline-block;
                        width: 100%;
                        
                        .ant-btn {
                            width: 100%;
                            height: 40px;
                            border-radius: 6px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            background-color: #41b65325;
                            color: #41b653;
                            border: 1px solid #41b65345;
                            
                            &:hover {
                                background-color: #41b65335;
                                border-color: #41b653;
                            }
                        }
                    }
                }
            }
        }
    }
    
    .empty-state {
        background-color: #f9fafb;
        border-radius: 8px;
        padding: 48px 24px;
        text-align: center;
        grid-column: 1 / -1;
        
        h4 {
            margin-bottom: 16px;
            color: #6b7280;
        }
    }
}

// Modal Styling
.ant-modal-content {
    border-radius: 8px;
    overflow: hidden;
    
    .ant-modal-header {
        padding: 16px 24px;
        
        .ant-modal-title {
            font-weight: 600;
            font-size: 18px;
            color: #111827;
        }
    }
    
    .ant-modal-body {
        padding: 24px;
        
        .ant-form-item-label > label {
            font-weight: 500;
            color: #374151;
        }
        
        .ant-input, .ant-input-password, .ant-picker {
            border-radius: 6px;
            
            &:hover, &:focus {
                border-color: #41b653;
            }
        }
        
        .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
            color: #41b653;
        }
        
        .ant-tabs-ink-bar {
            background-color: #41b653;
        }
        
        .form-actions {
            margin-top: 24px;
            display: flex;
            justify-content: flex-end;
        }
    }
}

.ant-btn-primary {
    background-color: #41b653;
    
    &:hover, &:active, &:focus {
        background-color: #329442 !important;
    }
}