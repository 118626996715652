.admin {
    padding: 6em 6em 5em 6em;
    min-height: 80vh;

    @media (max-width: 1280px) {
        padding: 6em 4em 5em 4em;
    }

    @media (max-width: 768px) {
        padding: 6em 2em 5em 2em;
    }

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;

        .admin-info {
            display: flex;
            align-items: center;
            gap: 20px;

            p {
                text-align: end;

                span {
                    color: #7d7d7d;
                }
            }
        }

        @media (max-width: 768px) {
            flex-direction: column;
            gap: 0;
            align-items: start;
            margin-bottom: 5px;

            h1 {
                margin-bottom: 2px;
            }

            .admin-info {
                flex-direction: column;
                gap: 10px;
                align-items: start;
                text-align: start;
                margin-bottom: 20px;

                p {
                    text-align: start;
                }
            }
        }
    }
}